import React, {useContext, useEffect, useMemo, useState} from 'react';
import Dropdown from '../../../common/components/Dropdown';
import SubmitDogProgressBar from '../../../common/components/SubmitDogProgressBar';
import {useCreateUpdateUserSubmission, useUserDraftedSubmission,} from '../../../common/hooks/useUserSubmission';
import {UserSubmissionStepTwo} from '../../../common/types/user-submissions';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {getAllStates} from '../../../utils/helperUtils';
import {useNavigate} from 'react-router-dom';
import AppContext from '../../../common/context/app-context';
import {GUEST_ID_API, GUEST_TOKEN_KEY, guestLoginApi} from "../../../api/apiAuth";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const reqMsg = (fieldName = 'This field'): string => `${fieldName} is required`;

const validationSchema = Yup.object({
  city: Yup.string().required(reqMsg()),
  state: Yup.string().required(reqMsg()),
  country: Yup.string().required(reqMsg()),
  organization: Yup.string(),
  socialMediaAccounts: Yup.string(),
});

const initialValues: UserSubmissionStepTwo = {
  city: "",
  state: "",
  country: "",
  organization: "",
  socialMediaAccounts: "",
  step: 2,
};

const SubmitDogStepTwo = () => {
  const {me: user,isFirstStepPassed,  setIsFirstStepPassed} = useContext(AppContext);
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/submit-dog-step-2', 'submit dog step 2 page')

  }, []);
  const config =
    user
      ? {}
      : {
        baseURL: process.env.REACT_APP_SERVER_API?.replace(
          'client',
          'guest',
        ),
      }

  const userId = user ? user?.id : localStorage.getItem(GUEST_ID_API);

  const {submissionCreateUpdate} = useCreateUpdateUserSubmission();
  const { data: userSubmissionDrafted } = useUserDraftedSubmission(userId, config);
  const navigate = useNavigate();
  const [recaptchaVerified] = useState(true);

  const formData = useMemo(() => {
    const data = userSubmissionDrafted?.data?.steps[1];
    if (!data) {
      return initialValues;
    }
    return {
      city: data.city,
      state: data.state,
      country: data.country,
      organization: data.organization,
      socialMediaAccounts: data.socialMediaAccounts,
      step: 2,
    };
  }, [userSubmissionDrafted]);

  const onSubmit = (value: UserSubmissionStepTwo) => {
    submissionCreateUpdate(
      {
        payload: value,
        type: "update",
        config: config,
        id: userSubmissionDrafted?.id || 0,
      },
      {
        onSuccess: () => navigate("/submit-dog-step-3"),
        onError: async (error) => {

          if((error as any)?.response?.data?.errorCode === "NO_UID") {
            const guestToken = await guestLoginApi();
            localStorage.setItem(GUEST_ID_API, guestToken.id);
            localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
            setIsFirstStepPassed(false);
            navigate("/submit-dog-step-1");
          }

          console.error(error)

        },
      },
    );
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount={true}
      >
        {({ handleSubmit, isValid, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="container xs-container--sm xs-px-6 xs-py-6">
              <div className="flex flex-col justify-start">
                <div className="flex w-100 justify-between align-center sm-flex-col-reverse mb-6 xs-mb-2">
                  <h2 className="fs-h-3 lh-h-3 sm-fs-h-4 sm-lh-h-4 xs-fs-h-6 xs-lh-h-6 fw-700 c-text-black grow-1 mr-2 sm-mr-0">
                    Submit My Dog
                  </h2>
                  <SubmitDogProgressBar
                    currentStep={2}
                    stepsCount={userSubmissionDrafted?.data?.steps.length || 0}
                  />
                </div>
                <p className="fs-h-7 lh-h-7 fw-700 c-primary xs-fs-sm xs-lh-sm sm-fs-xl sm-lh-xl mb-12 sm-mb-8 xs-mb-6">
                  Your dog can get featured on Flipped on Dogs! We choose new
                  dogs every day.
                </p>
              </div>
              <div className="flex flex-col justify-start align-start flex-wrap w-100 border br bg-white px-5 py-8 xs-px-4 xs-py-4  xs-flex-col mb-12 xs-mb-0">
                <div className="flex jusity-start align-start flex-wrap w-100 row-gap-7 xs-row-gap-6">
                  <div className="w-33 px-3 xs-w-100 xs-px-0">
                    <div className="input-wrap sm-input-wrap xs-input-wrap--sm">
                      <Field
                        type="text"
                        className="input"
                        id="city"
                        placeholder="1"
                        name="city"
                      />
                      <label className="input-label" htmlFor="city">
                        City<span className="c-text-3">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="w-33 px-3 xs-w-100 xs-px-0 select-z">
                    <Dropdown
                      options={getAllStates().map((is) => ({
                        value: is.name,
                        label: is.name,
                      }))}
                      size="sm-input-wrap xs-input-wrap--sm"
                      label="State"
                      handleSelect={(d) => setFieldValue("state", d)}
                      id="state"
                      name="state"
                      val={values.state}
                    />
                  </div>
                  <div className="w-33 px-3 xs-w-100 xs-px-0">
                    <div className="input-wrap sm-input-wrap xs-input-wrap--sm">
                      <Field
                        type="text"
                        className="input"
                        id="country"
                        placeholder="1"
                        name="country"
                      />
                      <label className="input-label" htmlFor="country">
                        Country<span className="c-text-3">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="w-50 px-3 xs-w-100 xs-px-0">
                    <div className="input-wrap sm-input-wrap xs-input-wrap--sm">
                      <Field
                        type="text"
                        className="input"
                        id="organization"
                        placeholder="1"
                        name="organization"
                      />
                      <label className="input-label" htmlFor="organization">
                        Organization
                      </label>
                    </div>
                  </div>
                  <div className="w-50 px-3 xs-w-100 xs-px-0">
                    <div className="input-wrap sm-input-wrap xs-input-wrap--sm">
                      <Field
                        type="text"
                        className="input"
                        id="media-account"
                        placeholder="1"
                        name="socialMediaAccounts"
                      />
                      <label className="input-label" htmlFor="media-account">
                        Social Media Pages/Accounts
                      </label>
                    </div>
                  </div>
                </div>
                {/*<div className="flex flex-col mt-12 xs-mt-6 xs-justify-center xs-align-center pl-3 xs-pl-0">*/}
                {/*  <ReCAPTCHA*/}
                {/*    sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}*/}
                {/*    onChange={onRecaptchaChange}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="flex justify-start align-center mt-12 sm-mx-auto xs-mt-6  xs-w-100 pl-3 xs-pl-0">
                  <button
                    className={`btn btn--secondary xs-btn--lg xs-w-100 ${
                      (!isValid || !recaptchaVerified) && "btn--disabled"
                    }`}
                    type="submit"
                  >
                    submit and continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SubmitDogStepTwo;
