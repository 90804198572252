import {useMutation, useQuery} from 'react-query';
import {getUserDraftedSubmission, userSubmissionCreateUpdateApi,} from '../../api/apiUserSubmission';

export const useUserDraftedSubmission = (userId: string | number | null, config: any) => {
  return useQuery({
    queryKey: 'useSubmissionDraft',
    queryFn: () => getUserDraftedSubmission(userId || 0, config),
    enabled: !!userId,
  });
};

export const useCreateUpdateUserSubmission = () => {
  const {
    mutate: submissionCreateUpdate,
    isLoading: submissionCreateUpdateLoading,
  } = useMutation("userSubmissionCreateUpdate", userSubmissionCreateUpdateApi);

  return {
    submissionCreateUpdate,
    submissionCreateUpdateLoading,
  };
};
