import React, {useContext, useEffect, useMemo, useState} from 'react';
import Dropdown from '../../../common/components/Dropdown';
import SubmitDogProgressBar from '../../../common/components/SubmitDogProgressBar';
import dogAges from '../../../common/data/dogAges.json';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom';
import {UserSubmissionStepOne} from '../../../common/types/user-submissions';
import {useBreed} from '../../../common/hooks/useBreed';
import {Breed} from '../../../common/types/breed';
import {useCreateUpdateUserSubmission, useUserDraftedSubmission,} from '../../../common/hooks/useUserSubmission';
import AppContext from '../../../common/context/app-context';
import {GUEST_ID_API, GUEST_TOKEN_KEY, guestLoginApi} from "../../../api/apiAuth";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const reqMsg = (fieldName = 'This field'): string => `${fieldName} is required`;

const validationSchema: any = Yup.object({
  dogName: Yup.string().required(reqMsg()),
  dogAge: Yup.string().required(reqMsg()),
  dogSex: Yup.string().required(reqMsg()),
  dogBreedId: Yup.number().required(reqMsg()),
  fullName: Yup.string().required(reqMsg()),
  dogBreedOther: Yup.string().required(reqMsg()),
  email: Yup.string().email("Invalid email address").required(reqMsg()),
  mobile: Yup.string(),
  privacyAccept: Yup.boolean().required(reqMsg()).oneOf([true], 'Field must be checked')
});

const initialValues: UserSubmissionStepOne = {
  dogName: "",
  dogAge: "",
  dogSex: "",
  dogBreedId: 0,
  fullName: "",
  dogBreedOther: "",
  email: "",
  mobile: "",
  privacyAccept: false,
  step: 1,
};

const SubmitDogStepOne = () => {
  const {me: user, isFirstStepPassed} = useContext(AppContext);

  const config =
    user
      ? {}
      : {
        baseURL: process.env.REACT_APP_SERVER_API?.replace(
          'client',
          'guest',
        ),
      }

  const userId = user ? user?.id : localStorage.getItem(GUEST_ID_API);

  const {data: breeds} = useBreed();
  const {data: userSubmissionDrafted} = useUserDraftedSubmission(userId, config);
  const {submissionCreateUpdate} = useCreateUpdateUserSubmission();
  const navigate = useNavigate();
  const [recaptchaVerified, setRecaptchaVerified] = useState(true);
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/submit-dog-step-1', 'submit dog step 1 page')

  }, []);
  const formData = useMemo(() => {
    const data = userSubmissionDrafted?.data?.steps[0];
    if (!data) {
      return initialValues;
    }

    const firstStepPassed = userSubmissionDrafted?.data?.steps?.length > 0;

    if (data.dogBreedOther) {
      validationSchema.fields.dogBreedOther = Yup.string().required(reqMsg());
    } else {
      validationSchema.fields.dogBreedOther = Yup.string();
    }

    return {
      dogName: data.dogName,
      dogAge: data.dogAge,
      dogSex: data.dogSex,
      dogBreedId: data.dogBreedId || 0,
      fullName: data.fullName,
      dogBreedOther: data.dogBreedOther,
      email: data.email,
      mobile: data.mobile,
      step: 1,
      privacyAccept: firstStepPassed
    };
  }, [userSubmissionDrafted]);

  const onSubmit = (value: UserSubmissionStepOne) => {
    if (value.dogBreedId == 0) {
      delete value.dogBreedId;
    } else if (value.hasOwnProperty('dogBreedOther')) {
      delete value.dogBreedOther;
    }
    if (value.privacyAccept) {
      delete value?.privacyAccept;
    }
    submissionCreateUpdate(
      {
        payload: value,
        type: !!userSubmissionDrafted?.data ? "update" : "create",
        config: config,
        id: userSubmissionDrafted?.id || 0,
      },
      {
        onSuccess: () => navigate("/submit-dog-step-2"),
        onError: async (error) => {

          if((error as any)?.response?.data?.errorCode === "NO_UID") {
            const guestToken = await guestLoginApi();
            localStorage.setItem(GUEST_ID_API, guestToken.id);
            localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);

            submissionCreateUpdate(
              {
                payload: value,
                type: !!userSubmissionDrafted?.data ? "update" : "create",
                config: config,
                id: userSubmissionDrafted?.id || 0,
              },
              {
                onSuccess: () => navigate("/submit-dog-step-2"),
                onError: (error) => {
                  console.error(error)
                },
              },
            );
          }

          console.error(error);
        },
      },
    );
  };

  const onRecaptchaChange = (value: any) => {
    setRecaptchaVerified(!!value);
  };

  const breedOptions = useMemo(() => {
    const breedOpt =
      breeds?.map((b: Breed) => ({
        value: b.id,
        label: b.name,
      })) || [];
    breedOpt.unshift({
      label: "Other",
      value: 0,
    });
    return breedOpt;
  }, [breeds]);

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {({handleSubmit, isValid, setFieldValue, values}) => (
          <form onSubmit={handleSubmit}>
            <div className="container xs-container--sm sm-px-6 xs-pb-6 xs-px-6">
              <div className="flex flex-col justify-start">
                <div className="flex w-100 justify-between align-center sm-flex-col-reverse mb-6 xs-mb-2">
                  <h2 className="fs-h-3 lh-h-3 sm-fs-h-4 sm-lh-h-4 xs-fs-h-6 xs-lh-h-6 fw-700 c-text-black grow-1 mr-2 sm-mr-0">
                    Submit My Dog
                  </h2>
                  <SubmitDogProgressBar
                    currentStep={1}
                    stepsCount={userSubmissionDrafted?.data?.steps.length || 0}
                  />
                </div>
                <p className="fs-h-7 lh-h-7 fw-700 c-primary xs-fs-sm xs-lh-sm sm-fs-xl sm-lh-xl mb-12 sm-mb-8 xs-mb-6">
                  Your dog can get featured on Flipped on Dogs! We choose new
                  dogs every day.
                </p>
                <div className="flex flex-col justify-start align-start flex-wrap w-100 border br bg-white px-5 py-8 xs-px-4 xs-py-4 row-gap-7 xs-row-gap-6 xs-flex-col mb-12 xs-mb-6">
                  <div className="flex justify-start align-start flex-wrap w-100 row-gap-7 xs-row-gap-6 xs-flex-col">
                    <div className="w-50 px-3 xs-w-100 xs-px-0">
                      <div className="input-wrap sm-input-wrap xs-input-wrap--sm">
                        <Field
                          type="text"
                          className="input"
                          id="nameD"
                          placeholder="1"
                          name="dogName"
                        />
                        <label className="input-label" htmlFor="nameD">
                          Your Dog's Name<span className="c-text-3">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="w-50 px-3 xs-w-100 xs-px-0 select-z">
                      <Dropdown
                        options={dogAges.map((da) => ({
                          value: da,
                          label: da,
                        }))}
                        size="sm-input-wrap xs-input-wrap--sm"
                        label="Dogs Age"
                        handleSelect={(d) => setFieldValue('dogAge', d)}
                        name="dogAge"
                        id="dogage"
                        val={values.dogAge}
                      />
                    </div>
                    <div className="w-50 px-3 xs-w-100 xs-px-0 select-z">
                      <Dropdown
                        options={[
                          {
                            value: "male",
                            label: "Male",
                          },
                          {
                            value: "female",
                            label: "Female",
                          },
                        ]}
                        size="sm-input-wrap xs-input-wrap--sm"
                        label="Dogs Sex"
                        handleSelect={(d) => setFieldValue('dogSex', d)}
                        name="dogSex"
                        id="dogsex"
                        val={values.dogSex}
                      />
                    </div>
                    <div className="w-50 px-3 xs-w-100 xs-px-0 select-z"
                         style={{display: !values.dogBreedOther ? 'block' : 'none'}}>
                      <Dropdown
                        options={breedOptions}
                        size="sm-input-wrap xs-input-wrap--sm"
                        label="Dog Breed"
                        handleSelect={(d) => {
                          if (d != 0) {
                            validationSchema.fields.dogBreedOther = Yup.string();
                          } else {
                            validationSchema.fields.dogBreedOther = Yup.string().required(reqMsg());
                          }
                          setFieldValue('dogBreedId', d);
                        }}
                        name="dogBreedId"
                        id="dogbreed"
                        val={values.dogBreedId}
                      />
                    </div>

                    <div className="w-50 px-3 xs-w-100 xs-px-0"
                         style={{display: values.dogBreedId == 0 ? 'block' : 'none'}}>

                    <div className="input-wrap  sm-input-wrap xs-input-wrap--sm">
                          <Field
                            type="text"
                            className="input"
                            id="breed-other"
                            placeholder="1"
                            name="dogBreedOther"
                          />
                          <label className="input-label" htmlFor="breed-other">
                            Other Breeds & Mixed Breeds
                            <span className="c-text-3">*</span>
                          </label>
                        </div>
                      </div>
                    <div className="w-50 px-3 xs-w-100 xs-px-0">
                      <div className="input-wrap  sm-input-wrap xs-input-wrap--sm">
                        <Field
                          type="text"
                          className="input"
                          id="name"
                          placeholder="1"
                          name="fullName"
                        />
                        <label className="input-label" htmlFor="name">
                          Your Full Name<span className="c-text-3">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="w-50 px-3 xs-w-100 xs-px-0">
                      <div className="input-wrap  sm-input-wrap xs-input-wrap--sm">
                        <Field
                          type="text"
                          className="input"
                          id="email"
                          placeholder="1"
                          name="email"
                        />
                        <label className="input-label" htmlFor="email">
                          Email<span className="c-text-3">*</span>
                        </label>
                      </div>
                    </div>
                    <div className="w-50 px-3 flex flex-col xs-w-100 xs-px-0">
                      <div className="input-wrap  sm-input-wrap xs-input-wrap--sm mb-2 ">
                        <Field
                          type="text"
                          className="input"
                          id="mobile"
                          placeholder="1"
                          name="mobile"
                        />
                        <label className="input-label" htmlFor="mobile">
                          Mobile #
                        </label>
                      </div>
                      <span className="fs-lg lh-lg fw-500 c-text-4 xs-fs-xs xs-lh-xs">
                        (We’ll text you in advance if your dog is going to be
                        featured.)
                      </span>
                    </div>
                  </div>
                  <div className="px-3 xs-px-0 mt-6 xs-mt-0">
                    <label
                      className={`checkbox-wrap cursor-pointer ${
                        isFirstStepPassed && 'input--disabled'
                      }`}
                    >
                      <Field
                        id="privacyAccept"
                        name="privacyAccept"
                        type="checkbox"
                        className="checkbox-input "
                        hidden
                      />
                      <span className="checkbox-icon-holder flipicon c-primary"></span>
                      <span className="checkbox-text fs-lg lh-md xs-fs-xs xs-lh-xs xs-ml-2 xs-pt-1 fw-500 c-text-4">
                        I consent to my submitted data being collected and
                        stored. I understand that my dog’s photos or videos may
                        be featured as a Pup Star or elsewhere on the platform.
                        View our{" "}
                        <Link
                          to="/privacy-policy"
                          className="c-primary underline"
                        >
                          Privacy Policy here
                        </Link>
                      </span>
                    </label>
                  </div>
                  {/*<div className="flex flex-col mt-12 xs-mt-6 px-3 xs-px-0 xs-justify-center xs-align-center">*/}
                  {/*  <ReCAPTCHA*/}
                  {/*    sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}*/}
                  {/*    onChange={onRecaptchaChange}*/}
                  {/*  />*/}
                  {/*</div>*/}
                  <div className="flex justify-start align-center px-3 xs-px-0 mt-12 sm-mx-auto xs-mt-6 xs-w-100">
                    <button
                      className={`btn btn--secondary xs-btn--lg xs-w-100 ${
                        (!isValid || !recaptchaVerified) && "btn--disabled"
                      }`}
                      type="submit"
                    >
                      submit and continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SubmitDogStepOne;
