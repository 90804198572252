import React, {useContext, useEffect, useMemo, useState} from "react";
import SubmitDogProgressBar from "../../../common/components/SubmitDogProgressBar";
import * as Yup from "yup";
import { UserSubmissionStepFour } from "../../../common/types/user-submissions";
import { Field, Formik } from "formik";
import {
  useCreateUpdateUserSubmission,
  useUserDraftedSubmission,
} from "../../../common/hooks/useUserSubmission";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../common/context/app-context";
import {GUEST_ID_API, GUEST_TOKEN_KEY, guestLoginApi} from "../../../api/apiAuth";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const validationSchema = Yup.object({
  dogFavoriteActivity: Yup.string().max(100),
  dogGettingIntoTrouble: Yup.string().max(100),
  dogBehaviorQuestion: Yup.string().max(100),
  dogStoryFact: Yup.string().max(200),
});

const initialValues: UserSubmissionStepFour = {
  dogFavoriteActivity: "",
  dogGettingIntoTrouble: "",
  dogBehaviorQuestion: "",
  dogStoryFact: "",
  step: 4,
};

const SubmitDogStepFour = () => {
  const { me: user } = useContext(AppContext);

  const config =
    user
      ? {}
      : {
        baseURL: process.env.REACT_APP_SERVER_API?.replace(
          'client',
          'guest',
        ),
      }

  const userId = user ? user?.id : localStorage.getItem(GUEST_ID_API);

  const { submissionCreateUpdate } = useCreateUpdateUserSubmission();
  const { data: userSubmissionDrafted } = useUserDraftedSubmission(userId, config);
  const navigate = useNavigate();
  const [recaptchaVerified] = useState(true);
  useEffect(() => {

    useTrackGoogleAnalyticsEventPages('/submit-dog-step-4', 'submit dog step 4 page')

  }, []);
  const formData = useMemo(() => {
    const data = userSubmissionDrafted?.data?.steps[3];
    if (!data) {
      return initialValues;
    }
    return {
      dogFavoriteActivity: data.dogFavoriteActivity,
      dogGettingIntoTrouble: data.dogGettingIntoTrouble,
      dogBehaviorQuestion: data.dogBehaviorQuestion,
      dogStoryFact: data.dogStoryFact,
      step: 4,
    };
  }, [userSubmissionDrafted]);

  const onSubmit = (value: UserSubmissionStepFour) => {
    submissionCreateUpdate(
      {
        payload: value,
        type: "update",
        config: config,
        id: userSubmissionDrafted?.id || 0,
      },
      {
        onSuccess: () => navigate("/submit-dog-step-5"),
        onError: async (error) => {
          if((error as any)?.response?.data?.errorCode === "NO_UID") {
            const guestToken = await guestLoginApi();
            localStorage.setItem(GUEST_ID_API, guestToken.id);
            localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
            navigate("/submit-dog-step-1");
          }
          console.error(error)
        },
      },
    );
  };

  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount={true}
      >
        {({ handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <div className="container xs-container--sm xs-px-6 xs-py-6">
              <div className="flex flex-col mb-6">
                <div className="flex w-100 justify-between align-center sm-flex-col-reverse mb-6 xs-mb-2">
                  <h2 className="fs-h-3 lh-h-3 sm-fs-h-4 sm-lh-h-4 xs-fs-h-6 xs-lh-h-6 fw-700 c-text-black grow-1 mr-2 sm-mr-0">
                    Submit My Dog
                  </h2>
                  <SubmitDogProgressBar
                    currentStep={4}
                    stepsCount={userSubmissionDrafted?.data?.steps.length || 0}
                  />
                </div>
                <p className="fs-h-7 lh-h-7 fw-700 c-primary xs-fs-sm xs-lh-sm sm-fs-xl sm-lh-xl ">
                  Tell us a quick little story about your awesome dog!
                </p>
              </div>
              <div className="flex flex-col justify-start flex-wrap align-start w-100 border br bg-white px-5 py-8 xs-px-4 xs-py-4  xs-flex-col">
                <div className="flex justify-start flex-wrap align-start w-100">
                  <div className="flex flex-col w-50 xs-w-100 px-3 xs-px-0 mb-4">
                    <div className="input-wrap input-wrap--md xs-input-wrap  w-100">
                      <Field
                        as="textarea"
                        rows={6}
                        cols={0}
                        maxlength={100}
                        className="input"
                        id="feedback-input"
                        placeholder="Your dog's favorite activity"
                        name="dogFavoriteActivity"
                      ></Field>
                    </div>
                    <span className="fs-xs lh-xs fw-500 c-text-4 mt-2">
                      (100 characters max)
                    </span>
                  </div>
                  <div className="flex flex-col w-50 xs-w-100 px-3 xs-px-0 mb-4">
                    <div className="input-wrap input-wrap--md xs-input-wrap  w-100">
                      <Field
                        as="textarea"
                        rows={6}
                        cols={0}
                        maxlength={100}
                        className="input"
                        id="feedback-input"
                        placeholder="Your dog's way of getting into trouble"
                        name="dogGettingIntoTrouble"
                      ></Field>
                    </div>
                    <span className="fs-xs lh-xs fw-500 c-text-4 mt-2">
                      (100 characters max)
                    </span>
                  </div>
                  <div className="flex flex-col w-50 xs-w-100 px-3 xs-px-0 mb-4">
                    <div className="input-wrap input-wrap--md xs-input-wrap  w-100">
                      <Field
                        as="textarea"
                        rows={6}
                        cols={0}
                        maxlength={100}
                        className="input"
                        id="feedback-input"
                        placeholder="A question you have about your dog's behavior"
                        name="dogBehaviorQuestion"
                      ></Field>
                    </div>
                    <span className="fs-xs lh-xs fw-500 c-text-4 mt-2">
                      (100 characters max)
                    </span>
                  </div>
                  <div className="flex flex-col w-50 xs-w-100 px-3 xs-px-0 mb-4">
                    <div className="input-wrap input-wrap--md xs-input-wrap  w-100">
                      <Field
                        as="textarea"
                        rows={6}
                        cols={0}
                        maxlength={200}
                        className="input"
                        id="feedback-input"
                        placeholder="Interesting fact or quick little story about your dog"
                        name="dogStoryFact"
                      ></Field>
                    </div>
                    <span className="fs-xs lh-xs fw-500 c-text-4 mt-2">
                      (200 characters max)
                    </span>
                  </div>
                </div>

                <div className="flex flex-col mt-12 xs-mt-6 justify-start xs-justify-center xs-align-center pl-3 xs-mx-auto ">
                  {/*<ReCAPTCHA*/}
                  {/*  sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}*/}
                  {/*  onChange={onRecaptchaChange}*/}
                  {/*/>*/}
                </div>
                <div className="flex justify-start align-center mt-12 xs-mx-auto mb-3 xs-mt-6 xs-mb-0  xs-w-100 pl-3 xs-pl-0">
                  <button
                    className={`btn btn--secondary xs-btn--lg xs-w-100 ${
                      (!isValid || !recaptchaVerified) && "btn--disabled"
                    }`}
                    type="submit"
                  >
                    submit and continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SubmitDogStepFour;
