export const routeNames = [
  {
    route: "/scrap-book",
    name: "Scrap Book",
    hide: true
  },
  {
    route: "/feedback",
    name: "Feedback"
  },
  {
    route: "/facebook-data-deletion-instructions",
    name: "Facebook Data deletion instructions",
    hide: true
  },
  {
    route: "/topics",
    name: "Topics",
  },
  {
    route: "/topics/:slug",
    name: "Sub Topics",
  },
  {
    route: "/article",
    chaneRoute: "/topics",
    name: "Topics",
  },
  {
    route: "/course",
    name: "Course",
    hide: true
  },
  {
    route: "/daily-reminders",
    name: "Reminders",
  },
  {
    route: "/about-us",
    name: "About us",
  },
  {
    route: "/community-guidelines",
    name: "Community guidelines",
  },
  {
    route: "/how-to-play",
    name: "How to play",
  },
  {
    route: "/privacy-policy",
    name: "Privacy policy",
  },
  {
    route: "/submit-dog",
    name: "Submit my dog",
  },
  {
    route: "/game",
    name: "Game",
    hide: true
  },
  {
    route: "/score",
    name: "Score",
  },
  {
    route: "/",
    name: "Home",
  },
];
